.recordercls .form {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    max-width: 250px;
    width: 100%;
    height: 80vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px;
    text-align: center;
}

.recordercls audio {
    width: 100%;
}

.recordercls .recordingLabel {
    font-size: 20px;
}

.recordercls .btn {
    background-color: #c4c4c4c4;
    width: 100px;
    height: 40px;
    border: none;
    margin: 5px;
    cursor: pointer;
}

.recordercls .goals {
    padding: 17px;
    border: 1px solid #c4c4c4;
    margin-bottom: 15px;
    border-radius: 15px;
    cursor: pointer;
}

.recordercls .goals a {
    text-decoration: none;
}

.recordercls .goal-active {
    background-color: #dbdbdb;
}

.main-title {
    font-weight: bold;
}

.secondary-title {
    font-weight: bold;
    /* font-size: 1.5rem; */
}

.highlight-yellow {
    background-color: #FFD233;
    color: #000;
    font-size: 1.2rem;
}

.hint-text {
    color: #969595;
    
}

.main-text {
    font-weight: lighter;
    font-size: 1.9rem;
}
