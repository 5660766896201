.onboardingcls .form {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    max-width: 250px;
    width: 100%;
    height: 80vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px;
    text-align: center;
}

.onboardingcls .btn {
    background-color: #c4c4c4c4;
    width: 100px;
    height: 40px;
    border: none;
    margin-top: 25px;
    cursor: pointer;
}

.onboardingcls .selector1 {
    height: 50px;
    width: 100%;
    position: relative;
    margin: 0 auto;
}

.onboardingcls .goals {
    padding: 17px;
    border: 1px solid #c4c4c4;
    margin-bottom: 15px;
    border-radius: 15px;
    cursor: pointer;
}

.onboardingcls .dominatorpic {
    width: 100%;
    height: 203px;
    object-fit: cover;
    object-position: 20% -2%;
}

.onboardingcls a {
    text-decoration: none;
}

.onboardingcls .goal-active {
    border: 2px solid red; 
}