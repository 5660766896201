.invitecls .form {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    max-width: 250px;
    width: 100%;
    height: 80vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px;
    text-align: center;
}

.invitecls .btn {
    background-color: #c4c4c4c4;
    width: 140px;
    height: 27px;
    border: none;
    margin-bottom: 15px;
    margin-top: 10px;
    cursor: pointer;
}

.invitecls .goals {
    padding: 17px;
    border: 1px solid #c4c4c4;
    margin-bottom: 15px;
    border-radius: 15px;
    cursor: pointer;
}