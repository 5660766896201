* {
  color: white;
  font-family: "Anonymous Pro", monospace;
  font-weight: bold;
}

.form {
  background-color: #131313;
}

.section-title {
  background-color: black;
  padding: 10px;
}

.section-title-white {
  color: black;
  background-color: white;
  padding: 10px;
  margin-bottom: 4px;
}

.handwrited {
  font-family: "Gochi Hand", cursive;
  font-size: 2.75em;
  text-transform: uppercase;
  font-weight: 200;
  line-height: 95%;
  margin: 0 auto 20px auto;
}

.red-handwrited {
  font-family: "Gochi Hand", cursive;
  color: red;
}
.goals {
  padding: 17px;
  border: 2px solid black !important;
  background-color: white;
  margin-bottom: 15px;
  border-radius: 10px !important;
  cursor: pointer;
}
.goals p,
.goals span,
.goals audio {
  background-color: white;
}

.goals div {
  color: black !important;
}

.btn {
  background-color: transparent !important;
  border: 2px solid #fff !important;
  text-align: center;
  width: auto !important;
  padding: 0px 10px;
  max-width: 100%;
  min-width: 100px;
}

.selector1 {
  padding: 0.5em 3.5em 0.5em 1em;
  margin: 0;
  height: auto !important;
  text-indent: 1px;
  border: 2px solid #d7d7d7;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  background-color: transparent;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.selector1:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: white;
  outline: 0;
}

option {
  color: black;
  padding: 0.5em 3.5em 0.5em 1em;
  border: 2px solid #d7d7d7;
  background-color: transparent !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.top {
  width: 86%;
  position: absolute;
  top: 8%;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.bottom {
  text-align: center;
  position: absolute;
  bottom: 8%;
  margin: 0 auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.green {
  background-color: #4ecb71 !important;
  border: none !important;
  padding: 0px 20px !important;
}

@media screen and (max-height: 650px) {
  .form {
    min-height: 525px !important;
    height: auto !important;
  }
}

/* STYLES */

button {
  border: 2px solid #fff;
  padding: 15px 30px;
  text-align: center;
  background-color: #ffffff0a;
  text-transform: uppercase !important;
}

input {
  color: black;
  background-color: white;
  padding: 15px;
  text-align: center;
  border: none;
}

.PhoneInputInput {
  color: black;
  background-color: white;
  padding: 15px;
  text-align: center;
  border: none;
}
/* .PhoneInputCountry {
  display: none !important;
} */

.select-goal {
  background-color: white;
}
.goal {
  color: black;
  font-weight: 700;
  background-color: white;
}
.new-motivation {
  color: #ffd233;
  font-weight: 700;
  background-color: transparent !important;
  border: 2px solid #ffd233 !important;
}
.motivation {
  color: black;
  font-weight: 700;
  background-color: #ffd233 !important;
}

.goal-description {
  color: black;
  background-color: white;
}
